.sp-contact {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;

    &__form {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 50px;
        max-width: 500px;
        width: 100%;

        &__input {
            border: none;
            border-bottom: 1px solid grey;
            font-family: 'Playfair Display';
            height: 25px;
            margin-bottom: 15px;
            width: 100%;
        }

        &__textarea {
            border: none;
            border-bottom: 1px solid grey;
            height: 30px;
            font-family: 'Playfair Display';
            margin-top: 30px;
            margin-bottom: 15px;
            width: 100%;
        }

        &__button {
            border: none;
            background-color: black;
            color: white;
            height: 35px;
            width: 100px;
        }
    }
}