.sp-header {
    align-items: center;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: space-evenly;

    &__logo {
        p {
            margin: 0;
            margin-bottom: 8px;
        }

        @media screen and (min-width: 600px) {
            font-size: 20px;
        }
    }

    &__nav {

        &__list {
            align-items: center;
            display: flex;
            flex-direction: row;
            list-style-type: none;
    
            li {
                color: grey;
                cursor: pointer;
                margin: 0 20px;

                &:hover {
                    color: rgb(207, 207, 207);
                }
            }
        }

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    &__menu {

        @media screen and (min-width: 601px) {
            display: none;
        }
    }

    &__hamburger {
        background-color: black;
        left: 0;
        position: absolute;
        list-style-type: none;
        top: 80px;
        width: 100%;
        z-index: 999;

        li {
            margin: 10px 0;
        }
    }

}
