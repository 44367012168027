.sp-footer {
    align-items: center;
    display: flex;
    height: 350px;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 670px) {
        height: auto;
    }

    &__container {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 30px;
        max-width: 1200px;
        width: 100%;

        @media screen and (max-width: 670px) {
            flex-direction: column;
            margin-top: 40px;
        }


        &__work {
            text-align: left;
            width: 35%;

            h2 {
                margin-top: 0;
            }

            &__social {
                img {
                    cursor: pointer;
                    margin-right: 10px;
                }
            }

            &__copyright {
                color: gray;
                font-size: 13px;

                @media screen and (max-width: 670px) {
                    display: none;
                }
            }

            @media screen and (max-width: 900px) {
                width: 45%;
            }

            @media screen and (max-width: 670px) {
                width: 100%;
            }
        }
    
        &__logo {
            width: 30%;

            img {
                width: 150px;
            }

            @media screen and (max-width: 900px) {
                display: none;
            }
        }
    
        &__contact {
            width: 35%;
    
            > .sp-contact__form {
                max-width: unset;
            }

            @media screen and (max-width: 900px) {
                width: 45%;
            }

            @media screen and (max-width: 670px) {
                margin-top: 40px;
                width: 100%;
            }
        }

        &__slogo {
            width: 150px;

            @media screen and (min-width: 670px) {
                display: none;
            }
        }

        &__copyright {
            color: gray;
            font-size: 13px;

            @media screen and (min-width: 670px) {
                display: none;
            }
        }
    }

}