.sp-memories {
    align-items: center;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;

    > h2 {
        color: gray;
        font-weight: 400;
    }
    
    &__container {
        max-width: 1200px;
        min-height: 300px;

        // img {
        //     height: 150px;
        //     margin: 10px;
        // }
    }

    &__load-button {
        border: none;
        background-color: white;
        color: black;
        height: 35px;
        width: 200px;
    }
}


.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Pattern specific pentru ecrane mari */
.image-grid img:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.image-grid img:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.image-grid img:nth-child(3) {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
}

.image-grid img:nth-child(4) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.image-grid img:nth-child(5) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

/* Responsive design pentru ecrane mai mici */
@media (max-width: 768px) {
    .image-grid {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .grid-image {
        width: 100%;
        height: auto;
    }
}


