.sp-home {
    
    &__blank {
        align-items: center;
        background-color: black;
        color: white;
        display: flex;
        justify-content: center;
        height: 100vh;
        width: 100%;

        > p {
            color: gray;
            font-size: 30px;
            margin: 0;

            @media screen and (max-width: 600px) {
                font-size: 20px;
            }
        }
    }

    &__phone {
        background-color: black;
        color: white;
        padding: 10px 30px;

        h1 {
            margin: 0;
        }

        p {
            margin: 0;
            padding-top: 20px;
        }

        @media screen and (min-width: 1000px) {
            display: none;
        }
    }

    &__container {
        background-color: black;
        background-image: url('../../assets/background1.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        height: 100vh;
        width: 100%;

        @media screen and (max-width: 800px) {
            height: 80vh;
        }

        @media screen and (max-width: 700px) {
            height: 70vh;
        }

        @media screen and (max-width: 600px) {
            height: 60vh;
        }

        @media screen and (max-width: 500px) {
            height: 50vh;
        }

        &__content {
            display: flex;
            justify-content: flex-end;
            max-width: 1200px;
            width: 100%;

            &__half {
                color: white;
                margin: 0 30px;
                max-width: 500px;
                padding-top: 100px;
                text-align: left;
    
                h1 {
                    background-color: rgba(0, 0, 0, 0.3);
                    font-size: 60px;
                    margin: 0;
                }

                p {
                    background-color: rgba(0, 0, 0, 0.3); 
                }
            }

            @media screen and (max-width: 1000px) {
                display: none;
            }
        }   
    }
}

.bounce2 {
    animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
}